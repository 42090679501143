import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import './index.less';
import { ServiceMenus } from './Menus';

const { Sider, Content } = Layout;

export const Services = () => {
  return (
    <Layout>
      <Sider theme="light">
        <ServiceMenus />
      </Sider>
      <Content style={{ padding: 24 }}>
        <main className="service-content">
          <Outlet />
        </main>
      </Content>
    </Layout>
  );
};
