import { MenuProps, Menu } from 'antd';
import { useMemo, useState } from 'react';
import Icon, { ToolOutlined } from '@ant-design/icons';
import { ReactComponent as ImageSvg } from '@/assets/image/image.svg';
import { useLocation, useNavigate } from 'react-router-dom';

type MenuItem = Required<MenuProps>['items'][number];

const items: MenuItem[] = [
  {
    key: 'qrcode-svg',
    label: '二维码转 SVG',
    icon: <ToolOutlined />,
  },
  {
    key: 'media-source',
    icon: <Icon component={ImageSvg} />,
    label: '媒体资源',
  },
];

export const ServiceMenus = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const defaultSelectedKeys = useMemo(() => {
    const matched = location.pathname.match(/\/services\/(.*)/);
    return matched?.[1] || (items[0]?.key as string);
  }, [location]);

  const [selectedKeys, setSelectedKeys] = useState<string[]>([
    defaultSelectedKeys,
  ]);

  const onSelectChange: MenuProps['onSelect'] = ({ selectedKeys }) => {
    setSelectedKeys(selectedKeys);
    navigate(selectedKeys[0]);
  };

  return (
    <Menu
      mode="inline"
      defaultSelectedKeys={['qrcode-svg']}
      selectedKeys={selectedKeys}
      onSelect={onSelectChange}
      items={items}
    />
  );
};
